import React, { useState } from 'react';

import ToggleSidebar from './ToggleSidebar';
import LessonPlanGenerator from './LessonPlanGenerator'
import Support from './Support'

//ICONS
import Student_monitor_icon from './images/Student_monitor_icon.svg';
import lesson_plan_gen_icon from './images/lesson_plan_gen_icon.svg';
import support_icon from './images/support_icon.svg';


const Sidebar = ({ onSidebarItemClick, selectedComponent, isSidebarOpen, toggleSidebar  }) => {


  return (
    
    <div className={`faculty-sidebar ${isSidebarOpen ? '' : 'closed'}`}>
      <ul>
        <li className={`faculty-sidebar-item ${selectedComponent === 'StudentProgressMonitor' ? 'selected' : ''}`} onClick={() => onSidebarItemClick('StudentProgressMonitor')}>
        
          <div className='faculty-icon-title'>
            <img src={Student_monitor_icon} alt="Student progress" className='faculty-sidebar-icon'/><span>Student Progress Monitor</span>
          </div>
           
        </li>
        <li className={`faculty-sidebar-item ${selectedComponent === 'LessonPlanGenerator' ? 'selected' : ''}`} onClick={() => onSidebarItemClick('LessonPlanGenerator')}>
          <div className='faculty-icon-title'>
            <img src={lesson_plan_gen_icon} alt="Student progress" className='faculty-sidebar-icon'/><span>AI Lecture Plan Generator</span>
          </div>
        </li>

        <li className={`faculty-sidebar-item ${selectedComponent === 'Support' ? 'selected' : ''}`} onClick={() => onSidebarItemClick('Support')}>
          <div className='faculty-icon-title'>
            <img src={support_icon} alt="Support & Escalate" className='faculty-sidebar-icon'/><span>Support & Escalation</span>
          </div>
        </li>
      </ul>


    </div>
  );
}

export default Sidebar;

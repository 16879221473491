import React from 'react';
import './header.css'; // CSS file for styling the header
// import { Link } from 'react-router-dom';
import logo from './images/Bolt_IoT_Final_Logo.png'
import whatsapp_icon from './images/whatsapp_icon.png'
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname
  console.log("state localtion value", location);
  console.log("state from value", from);

  const login = () => {
    console.log("window hostname", window.location.hostname + '/api/login');
    let login_url = 'https://' + window.location.hostname + '/api/login';
    console.log(login_url);
    window.location.href = window.location.href + 'api/login';
    window.location.href = login_url;
    // window.location.href = 'http://localhost:5000/api/login';
  };  

  const chat_with_us = () => {
    console.log("window hostname", window.location.hostname + '/api/login');
    const url_data = "https://api.whatsapp.com/send/?phone=919309051639&text=I got this number from Learning Portal Login page. I am facing issues";
    window.open(url_data, "_blank");
  };

  if (from == "/ApiUsage"){
    login();
  }
  

  return (
    <header className='header-container'>
      <div className="header">
        <div className="background-overlay"></div> {/* Blackish overlay */}
          <div className="container">
            <img src={logo}  alt="Logo" className="logo" />
              {/* <div className="buttons-container">
                <button className="login-button" onClick={login}>Login</button>
                <button className="signup-button">Sign Up</button>
              </div> */}
          </div>
          <div className="center-content">
            <h2>Bolt IoT Trainings</h2>
            <button className="login-button" onClick={login}>
                <div class="button-content">
                    <span class="main-text">Login</span>
                    <span class="sub-text">Access training</span>
                </div>
            </button>
          </div>
      </div>

      {/* <div className="center-button-container">
        <Link to="/home" className="view-all-products">
          View all products
        </Link>
      </div> */}

      <div id="whatsapp_chat_iotml" className="container">
            <div id="whatsapp_button" className="whatsapp_button"  onClick={chat_with_us}>
                <button className="chat_with_us">Chat with us</button>
                <img className='whatsapp_image' src={whatsapp_icon}>
                </img>
            </div>
      </div>

        
      <footer className="footer">
        <div className="footer-left">© Bolt IoT 2023</div>
        <div className="footer-center">
          <a href="https://www.boltiot.com/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Use</a>
          <a href="https://www.boltiot.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
        <div className="footer-right"></div>
      </footer>
    </header>
  
);
};

export default Header;

import React from 'react';
import './Generatekey.css';
import copy_clipboard_icon from './images/copy_clipboard_icon.svg';

const ApiKeyPopupCard = ({ apiKey,onClose }) => {
    const handleCopyKey = () => {
        navigator.clipboard.writeText(apiKey);
        console.log("apiKey:",apiKey);
        alert('API Key copied to clipboard!');
    };

    const handleCancel = () => {
        onClose(); // Call the onClose prop to close the popup
    };

    return (
        <div className='api-key-popup-overlay'>
            <div className='api-key-popup'>
                <h2>Save API Key</h2>
                <div className='api-key-content'>
                <div className='api-warning-text'>Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again. If you lose this secret key, you'll need to generate a new one.</div>
                  <div className='api-text-and-copy'>
                    <div className='api-key-textbox'>{apiKey}</div>
                    <button className='copy-api-button' onClick={handleCopyKey}>
                        <img src={copy_clipboard_icon} alt="Copy Key" title="Copy API Key" />
                    </button>
                    </div>
                <button className='cancel-button' onClick={handleCancel}>
                    Done
                </button>
                </div>
            </div>
        </div>
    );
};

export default ApiKeyPopupCard;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './InternshipsStipendPage.css';
import axiosBaseURL from './httpCommon';
import Boltfooter from './Footer';
import BoltHeader from './BoltHeader';

import profile_img from './images/profile_image.png';
import home_icon from './images/home-icon.png'
import whatsapp_icon from './images/whatsapp_icon.png'

const AiDebuggerPage = () => {
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleProfileClick = () => {
        setProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const handleLogout = () => {
        axiosBaseURL.get('/logout')
            .then(response => {
                navigate('/');
            })
            .catch(error => {
                console.error('Error during logout:', error);
                navigate('/');
            });
    };

    useEffect(() => {
        const iframeLoadHandler = () => {
            setLoading(false);
        };

        const iframe = document.getElementById('debugger-iframe');
        iframe.addEventListener('load', iframeLoadHandler);

        return () => {
            iframe.removeEventListener('load', iframeLoadHandler);
        };
    }, []);

    return (
        <div className="internships-stipend-page">
            <BoltHeader/>
            {isLoading && (
                <div className="loading-spinner-container">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <iframe
                id="debugger-iframe"
                title="AI Code debugger"
                src="https://info.boltiot.com/debugger"
                width="100%"
                height="100%"
                allowFullScreen
            ></iframe>

                <Boltfooter/>
        </div>
    );
};

export default AiDebuggerPage;

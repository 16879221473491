import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosBaseURL from './httpCommon';
import GenerateKey from './GenerateKey';
import ApiUsageDetails from './ApiUsageDetails';
import BoltLibraryInstructions from './BoltLibraryInstructions';

import BoltHeader from './BoltHeader';
import Footer from './Footer';

import './ApiUsage.css';



const ApiUsage = () => {
    const [apiKey, setApiKey] = useState('');
    const [apiShortKey, setApiShortKey] = useState('Generate API key');
    const [apiUsageDetails, setApiUsageDetails] = useState(null);
    const [userData, setUserData] = useState(null); // State to store user data
    const [userName, setUserName] = useState("");
  
    const navigate = useNavigate();
    const location = useLocation();  

    useEffect(() => {
        const fetchApiUsageDetails = async () => {
            try {
                const response = await axiosBaseURL.post('/apikey/info');
                if (response.data.status && response.data.data) {
                    console.log("api details:",response.data);
                    setApiUsageDetails(response.data.data);
                    if (response.data.data.api_short_key){
                        setApiShortKey("...."+response.data.data.api_short_key);
                        } else {
                             setApiShortKey("Generate API key");
                        }
                }
            } catch (error) {
                console.error('Error fetching API usage details:', error);

	        navigate('/', {state:{from:location}});
            }
        };

        fetchApiUsageDetails();
    }, []);

    useEffect(() => {
        fetchTrainings();
    }, [])

    const fetchTrainings = () => {
        axiosBaseURL.get('/trainings/')
        .then((response) => {
            const data = response.data
            console.log(data)
            setUserData(data.data); // Store user data in state
            const { name } = response.data; // Extract the name from the response data
            setUserName(data.data.name); // Set the username in the state
        })
        .catch((error) => {
            //unable to authenticate
            console.log("couldnt fetch data");
            console.log(error);
        });
    }

    const handleCopyClick = (textToCopy) => {
        // Function to handle copy click in BoltLibraryInstructions component
        navigator.clipboard.writeText(textToCopy)
            .then(() => alert('Copied to clipboard!'))
            .catch(err => console.error('Error copying text:', err));
    };

    return (
        <div api-usage-content-container>
            <BoltHeader name={userName}/>
            <h1 className='api-heading'>API Usage</h1>
            <GenerateKey api_key={apiKey} apiShortKey={apiShortKey}/>
            <ApiUsageDetails apiUsageDetails={apiUsageDetails}/>
            <BoltLibraryInstructions handleCopyClick={handleCopyClick} />
            <br></br>
            <br></br>
            <br></br>
            <Footer />
        </div>
    );
};

export default ApiUsage;

import React, { useState, useEffect } from 'react';
import axiosBaseURL from './httpCommon';

import './FacultyDashboard.css'
import BoltHeader from './BoltHeader';
import Footer from './Footer';
import Sidebar from './Sidebar';
import StudentCard from './StudentCard';
import LessonPlanGenerator from './LessonPlanGenerator'; // Import the new component
import Support from './Support';
import './Sidebar.css'; 
import ToggleSidebar from './ToggleSidebar';



//images


const trainingNameMap = {
  "101":"Artificial Intelligence Training",
  "102":"Online Training on Internet of Things and Machine Learning",
  "103":"Web Development Training on HTML, CSS and JavaScript",
  "104":"Online Training on Python Programming",
  "105":"Voice Apps Online Training",
  "106":"Augmented Reality Training",
  "107":"Advanced IoT and ML Training",
  "108":"Placement Preparation Training",
  "109":"Online Training on Robotics and Arduino",
}


const FacultyDashboard = () =>{

  const [selectedComponent, setSelectedComponent] = useState('StudentProgressMonitor');
  const [studentData, setStudentData] = useState([]);
  const [isFacultyVerified, setIsFacultyVerified] = useState(false);
  const [isFacultyDataLoaded, setIsFacultyDataLoaded] = useState(false);
  const [userName, setUserName] = useState("");
  const [salutation, setSalutation] = useState("");
  const [examCompleted, setExamCompleted] = useState({
    "Artificial Intelligence Training": false,
    "Online Training on Internet of Things and Machine Learning": false,
    "Web Development Training on HTML, CSS and JavaScript": false,
    "Online Training on Python Programming": false,
    "Voice Apps Online Training": false,
    "Augmented Reality Training": false,
    "Advanced IoT and ML Training": false,
    "Placement Preparation Training": false,
    "Online Training on Robotics and Arduino": false,
  });
  // const [totalTopics, setTotalTopics] = useState(null);
  // const totalTopics = 120; // Hardcoded total number of topics

  const handleSidebarItemClick = (component) => {
    setSelectedComponent(component);
  };


  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosBaseURL.get('/student_data');
        console.log("student data",response.data)
        // setTotalTopics(response.data.TotalNumberOfTopics);
        const updatedStudentData = calculateProgress(response.data.students, response.data.TotalNumberOfTopics);
        // setStudentData(response.data.students);
        setStudentData(updatedStudentData);
        console.log("TotalNumberOfTopics",response.data.TotalNumberOfTopics)
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchFacultyData = async () => {
        try {
            const response = await axiosBaseURL.get('/faculty_data');
            console.log("Faculty data response:", response.data);
            const { name } = response.data; // Extract the name from the response data
            const {salutation}= response.data;
            setSalutation(salutation);
            setUserName(name); // Set the username in the state
            setIsFacultyVerified(response.data.faculty_verified); // Set faculty verification status based on API response
            setIsFacultyDataLoaded(true); // Set to true when data is successfully loaded
        } catch (error) {
            console.error('Error fetching faculty data:', error);
            setIsFacultyVerified(false);
            setIsFacultyDataLoaded(true); // Still set to true to indicate that data loading is completed
        }
    };

    fetchFacultyData();
}, []);
  
useEffect(() => {
  console.log('examCompleted state changed:', examCompleted);
}, [examCompleted]); 


// Function to calculate and update progress
const calculateProgress = (students, totalTopics) => {
  const updatedExamCompleted = { ...examCompleted }; 
  return students.map(student => {
    console.log(`Looping through student: ${student.name}`);
    console.log('totalTopics', totalTopics);
    const trainings = [];
    let trainings_found = [];
    // Check if topics_completed is defined and not null
    if (student.topics_completed && typeof student.topics_completed === 'object') {
      const topicsCompleted = student.topics_completed;

    Object.keys(topicsCompleted).forEach(topic => {
      console.log(`Topic: ${topic}, Completed: ${topicsCompleted[topic]}`);
      const completedTopicsCount = topicsCompleted[topic].length; 
      const totalTopicsForTraining = totalTopics && totalTopics[topic];
      console.log("total topics:", totalTopicsForTraining )
      const percentageCompletion = ((completedTopicsCount / totalTopicsForTraining) * 100).toFixed(2); // Limit decimal points to 2
      console.log(`Percentage completion for ${student.name} in ${topic}: ${percentageCompletion}%`);
      const progress = `${Math.min(percentageCompletion, 100)}%`;
      const trainingCompleted= "";
      const trainingCertificateCompleted="";
      // student.progress = progress;
      
      if (examCompleted[trainingNameMap[String(topic)]]) 
      {
        updatedExamCompleted[trainingNameMap[String(topic)]] = true;
        setExamCompleted(updatedExamCompleted);
        trainings.push({ 
          training_name: trainingNameMap[String(topic)], 
          progress: '100%', 
          trainingCompleted: 'yes', 
          trainingCertificateCompleted: 'completed'
        });
        console.log("exam completed :");
      } else {
        trainings.push({ 
          training_name: trainingNameMap[String(topic)], 
          progress: progress, 
          trainingCompleted: 'no', 
          trainingCertificateCompleted: 'incomplete' 
        });
        console.log("exam not completed:");
      }
      // trainings.push({ training_name: trainingNameMap[String(topic)], progress: progress,trainingCompleted:trainingCompleted,trainingCertificateCompleted:trainingCertificateCompleted });
      trainings_found.push(trainingNameMap[String(topic)]);
      console.log("trainings_found", trainings_found);
    });
      // console.log(`Progress for ${student.name}: ${progress}`);
      student.trainings = trainings;
    } else {
      // If topics_completed is null or undefined, set progress to 0%
      // student.trainings = [{ training_name: "No Training completed", progress: '0%' }];
      console.log(`No topics completed for ${student.name}. Progress: 0%`);
    }

    if(!student.trainings_enrolled){
      student.trainings_enrolled=[];
    }

    student.trainings_enrolled.forEach(training_name => {
      console.log("trainings_found check", trainings_found);
      if (!trainings_found.includes(training_name)){
        trainings.push({ training_name: training_name, progress: '0%' });
      }
      student.trainings = trainings;
    });
    return student;
  });
};

  //faculty dashboard content 
  return (
    
    <div className="faculty-dashboard-container" >
        <ToggleSidebar onClick={toggleSidebar} isFixed={isSidebarOpen} />
        <BoltHeader salutation={`Hello, ${salutation} ${userName}` }isFacultyDashboard={true}/> 
        {isFacultyDataLoaded && ( // Check if faculty data is loaded
      isFacultyVerified ? ( // Check if faculty is verified
        <>
        <Sidebar onSidebarItemClick={handleSidebarItemClick} selectedComponent={selectedComponent} isSidebarOpen={isSidebarOpen}/>
        
        <div className="content-container">
        {selectedComponent === 'StudentProgressMonitor' ? ( 
          studentData.length === 0 ? (
            <p>Student data is not available.</p>
         ) : (  
        studentData.map((student, index) => (
        <StudentCard key={index} serialNumber={index + 1} {...student}   />
        )))): selectedComponent === 'LessonPlanGenerator' ? (
          <LessonPlanGenerator />
          ) : selectedComponent === 'Support' ? ( // Check if the selectedComponent is 'Support'
          <Support /> // Render the Support component
          ) : null
      }
      </div>
      </>
       ) : (
        <div className="message-container">
         <p>We are unable to find your college information in our system. Request you send us an email on <a href="mailto:support@boltiot.com">support@boltiot.com</a> or call on <a href="tel:+917020581998">7020581998</a> so that we can update your details in our system and share the access with you.</p>

      </div>
      )
    )
    }
        <Footer />
        
    </div>  
    
  );
}

export default FacultyDashboard;

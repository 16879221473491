import React, { useState, useEffect } from 'react';
import axiosBaseURL from './httpCommon';

import './ApiUsageDetails.css';



const ApiUsageDetails = (apiUsageDetails) => {
    console.log('ApiUsageDetails - apiUsageDetails:', apiUsageDetails);

    if (!apiUsageDetails) {
        return <p>Loading API usage details...</p>;
    }


    return (
            <div className='api-usage-details-container'>
            <h2>API Usage Details</h2>
            <div className='usage-details-item'>
                <span>Input/Prompt Tokens</span>
                <span>{apiUsageDetails.apiUsageDetails?.llm_providers?.chatgpt['gpt-3_dot_5-turbo']?.prompt_tokens?.usage}/{apiUsageDetails.apiUsageDetails?.llm_providers?.chatgpt['gpt-3_dot_5-turbo']?.prompt_tokens?.quota}</span>
            </div>
            <div className='usage-details-item'>
                <span>Output/Response Tokens</span>
                <span>{apiUsageDetails.apiUsageDetails?.llm_providers?.chatgpt['gpt-3_dot_5-turbo']?.completion_tokens?.usage}/{apiUsageDetails.apiUsageDetails?.llm_providers?.chatgpt['gpt-3_dot_5-turbo']?.completion_tokens?.quota}</span>
            </div>
            <div className='usage-details-item'>
              <span>Images Generated</span>
              <span>{apiUsageDetails.apiUsageDetails?.llm_providers?.chatgpt['dall-e-3']?.images?.usage}/{apiUsageDetails.apiUsageDetails?.llm_providers?.chatgpt['dall-e-3']?.images?.quota}</span>	      
                {/* <span>{apiUsageDetails?.images_generated}/{apiUsageDetails?.image_quota}</span> */}
            </div>
        </div>
    );
};

export default ApiUsageDetails;

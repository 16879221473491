import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './InternshipsStipendPage.css';
import axiosBaseURL from './httpCommon';
import profile_img from './images/profile_image.png';
import home_icon from './images/home-icon.png';
import whatsapp_icon from './images/whatsapp_icon.png';
import './iframe.css'

const Iframe = ({ iframeUrl }) => {
    const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleProfileClick = () => {
        setProfileDropdownOpen(!isProfileDropdownOpen);
    };

    const handleLogout = () => {
        axiosBaseURL.get('/logout')
            .then(response => {
                navigate('/');
            })
            .catch(error => {
                console.error('Error during logout:', error);
                navigate('/');
            });
    };

    useEffect(() => {
        const iframeLoadHandler = () => {
            setLoading(false);
        };

        const iframe = document.getElementById('debugger-iframe');
        iframe.addEventListener('load', iframeLoadHandler);

        return () => {
            iframe.removeEventListener('load', iframeLoadHandler);
        };
    }, []);

    return (

        <div className="iframe-content">
            {/* ... (rest of your code) */}
            <iframe
                id="debugger-iframe"
                title="iframe"
                src={iframeUrl}
                width="100%"
                height="100%"
                allowFullScreen
                style={{
                    overflow: 'hidden',
                    border: '0',
                    display: 'block', // Ensures block-level rendering
                    margin: '0',
                    padding: '0',
                }}
            ></iframe>
            {/* ... (rest of your code) */}
        
        </div>
    );
};

export default Iframe;

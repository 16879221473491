// StudentCard.js

import React, { useState, useEffect } from 'react';
import axiosBaseURL from './httpCommon';

import './StudentCard.css'; 


const StudentCard = ({ serialNumber, name, college, batch, branch, rollno, progress, user_email, trainingname, trainings, trainingCompleted, trainingCertificateCompleted}) => {
  // console.log("Progress prop:", progress);

  const [isExpanded, setExpanded] = useState(false);
  const [percentageCompletion, setPercentageCompletion] = useState(null);

  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  if (!trainings) {
    trainings = []; // Set trainings to an empty array
  }

  return (
    <div className={`student-card ${isExpanded ? 'expanded' : ''}`}>
      <div className="card-header" onClick={toggleExpand}>
        <span className="student-serial">{serialNumber}.</span>
        <span className="student-name">{name}</span>
        <div className="arrow">{isExpanded ? '▲' : '▼'}</div>
      </div>
      {isExpanded && (
        <div className="card-details">
          <div><div className='student-details'><strong>College:</strong>&nbsp;<span className="detail-content">{college}</span></div></div>
          <div><div className='student-details'><strong>Branch:</strong>&nbsp;<span className="detail-content"> {branch}</span></div></div>
          <div><div className='student-details'><strong>Batch:</strong>&nbsp;<span className="detail-content"> {batch}</span></div></div>
          <div><div className='student-details'><strong>Roll No:</strong>&nbsp;<span className="detail-content"> {rollno}</span></div></div>
          <div><div className='student-details'><strong>Email id:</strong>&nbsp;<span className="detail-content">{user_email}</span></div></div>
          <div className='training-progress-map'>
            {trainings.map((training, index) => (
              <div className='student-details' key={index}>
              <span className="detail-content" id='training-name'><strong>Training:</strong>&nbsp;{training.training_name}</span>
              &nbsp;&nbsp;
              <span className="detail-content" id='progress-details'><strong>Progress:</strong>&nbsp;{training.progress}</span>
              <br></br>
              <br />
            </div>
          ))}
          </div>
           </div>
      )}
    </div>
  );
};

export default StudentCard;

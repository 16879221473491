import React from 'react';
import './CourseTimeline.css';
// import trophy_icon from './images/trophyicon.png';
import prize_icon from './images/prize_icon.png';

const CourseTimeline = () => {
  return (
    <div className="course-timeline">
      <div className="course-timeline-item">
        <div className='box-1'>
        <div className="timeline-circle filled-circle">1</div>
        <div className="timeline-content">Start Training</div>
        <div className="timeline-arrow" id='line-1'></div>
        </div>
      </div>
      <div className="course-timeline-item">
      <div className='box-1'>
        <div className="timeline-circle filled-circle">2</div>
        <div className="timeline-content">Training Certificate</div>
        <div className="timeline-arrow" id='line-2'></div>
        </div>
      </div>
      <div className="course-timeline-item">
      <div className='box-1'>
        <div className="timeline-circle filled-circle">3</div>
        <div className="timeline-content">Internship Project</div>
        <div className="timeline-arrow" id='line-3'></div>
        </div>
      </div>
      <div className="course-timeline-item" id='stage-four'>
      <div className='box-1'>
        <img src={prize_icon} alt="prize_icon" class="prize-icon"></img>
        <div className="timeline-circle filled-circle" id='fourth-circle'>4</div>
        <div className="timeline-content" id="fourth-title">Internship Certificate</div>
        </div>
      </div>
      
    </div>
  );
};

export default CourseTimeline;

import React from 'react';
import './ApiUsageDetails.css';


const BoltLibraryInstructions = ({ handleCopyClick }) => {
    return (
        <div className='api-usage-details-container'>
            <h2>Install Bolt IoT Library</h2>
            <p>Execute the following command in Repl "shell" tab:</p>
            <div className='code-block'>
                <code>pip install boltiotai</code>
                <button className='copy-button' id='cpy-btn-1' onClick={() => handleCopyClick("pip install boltiotai")}>Copy</button>
            </div>
            <br></br>
            <p>Add the following line in your code:</p>
            <div className='code-block'>
                <code>from boltiotai import openai</code>
                <button className='copy-button' id='cpy-btn-2' onClick={() => handleCopyClick("from boltiotai import openai")}>Copy</button>
            </div>
        </div>
    );
};

export default BoltLibraryInstructions;
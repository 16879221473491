import React, { useEffect, useState } from 'react';
import axiosBaseURL from './httpCommon';

import ApiKeyPopupCard from './ApiKeyPopupCard';

import './Generatekey.css';


const GenerateKey = ({ onGenerateKey, api_key, apiShortKey }) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [keyValue, setKeyValue] = useState('');
    const [showKey, setShowKey] = useState(false);
    const [apiKeyGenerated, setApiKeyGenerated] = useState(false);
    const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the popup
    const [shortApiKey, setShortApiKey] = useState(apiShortKey);

    useEffect(() => {
        console.log("effect", apiShortKey);
        setShortApiKey(apiShortKey);
    }, [apiShortKey]);
  // const predefinedApiKey = 'abcdefghijklmnopkrst';

    // useEffect(() => {
    //     console.log("state value", state);
    //     if (!state) {
    // 	    // Redirect to home if no state or email is found
    // 	    navigate('/');
    // 	}
    // }, []);

    const handleRadioChange = (e) => {
        setIsEnabled(e.target.value === 'enable');
    };

    const handleInputChange = (e) => {
        console.log("inside input change");
        setKeyValue(e.target.value);
        console.log("short key inpput change: ",apiShortKey);
    };

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleCopyKey = () => {
        navigator.clipboard.writeText(keyValue);
        alert('API Key copied to clipboard!');
    };

    const handleGenerateKey = async () => {
        try {
            const response = await axiosBaseURL.post('/apikey/generate');
            if (response.data.status && response.data.data.api_key) {
                console.log("short key:",response.data);
                setKeyValue(response.data.data.api_key); // Set generated API key
                setShortApiKey("...."+response.data.data.api_short_key); // Set generated short API key
                //setApiShortKey(response.data.data.api_short_key);
                console.log("short key value",response.data.data.api_short_key);
                setApiKeyGenerated(true);
                setShowKey(true);
                setShowPopup(true);
                console.log("attribute",shortApiKey);
            }
        } catch (error) {
            console.error('Error generating API key:', error);
        }
    };
    
    return (
        <div className='generate-key-container'>
            <div className='generate-key-content-container'>
            <h2>API Key</h2>
            {/*{apiKeyGenerated && (
            <div className='radio-buttons'>
            <div className='enable-radio-button'>
                <input
                    type="radio"
                    id="enable"
                    name="keyStatus"
                    value="enable"
                    checked={isEnabled}
                    onChange={handleRadioChange}
                />
                <label htmlFor="enable">Enable</label>
            </div>
            <div className='enable-radio-button'>
                <input
                    type="radio"
                    id="disable"
                    name="keyStatus"
                    value="disable"
                    checked={!isEnabled}
                    onChange={handleRadioChange}
                />
                <label htmlFor="disable">Disable</label>
            </div>
            </div>
            )}*/}
            <div className='api-key-field'>
                <input className='api-key-text'
                    type='text'
                    placeholder=""
                    value={shortApiKey}
                    onChange={handleInputChange}
                />
                <button className='generate-key-button' onClick={handleGenerateKey}> Generate Key</button>   
            </div>
        </div>
        {showPopup && (
                <ApiKeyPopupCard apiKey={keyValue} shortApiKey={apiShortKey} onClose={handleClosePopup} />
            )}
        </div>
    );
};



export default GenerateKey;

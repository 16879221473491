import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './TrainingsPage.css';
import axiosBaseURL from './httpCommon';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import 'prismjs/components/prism-python';

import topic_icon from './images/topic_icon.png'
import home_icon from './images/home-icon.png'
import whatsapp_icon from './images/whatsapp_icon.png'
import tick_icon from './images/tick_icon.svg'
import ring_icon from './images/ring_icon.svg'
import back_icon from './images/back_icon.png'
import intern_with_stipend_icon from './images/intern_with_stipend_icon.png';
import prev_icon from './images/prev_icon.svg';
import next_icon from './images/next_icon.svg';
import certificate_icon from './images/certificate_icon.svg'

import CircularProgress from './CircularProgress.js';

const TrainingsPage = () => {

  const { state } = useLocation(); // Get the state parameter from the previous page
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [trainingData, setTrainingData] = useState(null); 
  const [sidebarVisible, setSidebarVisible] = useState(true); // State to track sidebar visibility
  const [lessonContent, setLessonContent] = useState('');
  const [currentSelectionIndexes , setCurrentSelectionIndexes] = useState(0); 
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [completedLessons, setCompletedLessons] = useState({});
  const [updatedCompletedLessons] = useState([]);
  const [certificateTopicModuleIndex, setCertificateTopicModuleIndex] = useState('');
  const [internshipTopicModuleIndex, setInternshipTopicModuleIndex] = useState('');


  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);    
  };

  const body = document.querySelector('body');
    if (body) {
      body.classList.toggle('sidebar-hidden', !sidebarVisible);
    } 

  const navigate = useNavigate();

  useEffect(()=> {
    if (!state || !state.email) {
      // Redirect to home if no state or email is found
      navigate('/home');
    }
  })

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll"
    };
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // },[]);


  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    Prism.highlightAll();
    }, [lessonContent]); // Trigger the effect of syntax highlighting on content update

  useEffect(() => {
    if (state && state.id) {
      axiosBaseURL.get(`/trainings/${state.id}/curriculum/`)
        .then((response) => {
          const formattedData = {
            training_name: response.data.training_name,
            training_id: response.data.training_id,
            modules: response.data.modules,
          };
          setTrainingData(formattedData);
          console.log("response data")
          console.log(response.data)
          let module_index = 0;
          const fetchIdByFilename = (filename) => {
            for (const module of response.data.modules) {
              let topic_index = 0;
              for (const topic of module.topics) {
                if (topic.file_name === filename) {
                  return topic_index;
                }
                topic_index++;
              }
            }
            return 0; // Return null if filename is not found
          };

          let updatedCompletedLessonsTemp = {};  
          let moduleIndex = 0;
          for (const module of response.data.modules) {
            let topicIndex = 0;
            for (const topic of module.topics) {
              let locked_status = false;
            if (response.data.topics_completed.includes(parseInt(topic.id))) {
              locked_status = true;
            }
            if (updatedCompletedLessonsTemp[moduleIndex]){
              updatedCompletedLessonsTemp[moduleIndex][topicIndex] = locked_status;
              // console.log("updated in if", updatedCompletedLessonsTemp);

            } else {
              updatedCompletedLessonsTemp[moduleIndex] = [];
              updatedCompletedLessonsTemp[moduleIndex].push(locked_status);
              // console.log("updated in else", updatedCompletedLessonsTemp);
            }
            if (topic.is_certificate_topic){
              setCertificateTopicModuleIndex(moduleIndex + "_" + topicIndex)
            }

            if (topic.is_internship_topic){
              setInternshipTopicModuleIndex(moduleIndex + "_" + topicIndex)
            }

            topicIndex++;
          } moduleIndex++;
          }
          setCompletedLessons(updatedCompletedLessonsTemp);
          console.log(response.data.last_viewed_topic); 
          for (const module of response.data.modules){
            if (module.id === response.data.last_viewed_topic.module)
            {
              break;
            }
            module_index++;
          }
          console.log("module index", module_index);
          const last_viewed_topic = fetchIdByFilename(response.data.last_viewed_topic.topic);
          const last_viewed_module = module_index;
          // setSelectedModuleIndex(0);
          // setSelectedTopicIndex(0);
          setSelectedModuleIndex(last_viewed_module);
          setSelectedTopicIndex(last_viewed_topic);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [state]);

  useEffect(() => {
    fetchTopic();
  }, [trainingData])

  //added this use effect so that when either of the two (lesson or module) changes the fetch topic should trigger 
  useEffect(() => {
    fetchTopic();
  }, [currentSelectionIndexes])

  const handleLessonClick = (moduleIndex, lessonIndex, topic) => {
    if (topic == "certificate" || topic == "internship"){
      let indexes = [];
      if (topic == "certificate"){
        indexes = certificateTopicModuleIndex.split("_");
      }
      if (topic == "internship"){
        indexes = internshipTopicModuleIndex.split("_");
      }

      console.log("certificate indexes", certificateTopicModuleIndex, internshipTopicModuleIndex, indexes);
      moduleIndex = indexes[0];
      lessonIndex = indexes[1];
    }  

    const isCompleted = completedLessons[moduleIndex]?.[lessonIndex] || false;
    console.log("handle lesson click", moduleIndex, lessonIndex)
    setSelectedModuleIndex(moduleIndex);
    setSelectedTopicIndex(lessonIndex);
    setCurrentSelectionIndexes(moduleIndex + "_" + lessonIndex);
    toggleSidebar();
    
    if (isCompleted) {
      const lessonElement = document.querySelector(`.lesson-${moduleIndex}-${lessonIndex}`);
      if (lessonElement) {
        lessonElement.classList.add('completed');
      }
    }
  };

  const handlePrevLesson = () => {
    if (selectedTopicIndex > 0) {
      setSelectedTopicIndex(selectedTopicIndex - 1);
      setCurrentSelectionIndexes(selectedModuleIndex + "_" + (selectedTopicIndex - 1));
    } else if (selectedModuleIndex > 0) {
      setSelectedModuleIndex(selectedModuleIndex - 1);
  
      const prevModule = trainingData.modules[selectedModuleIndex - 1];
      const numLessonsInPrevModule = prevModule.topics.length;
      setSelectedTopicIndex(numLessonsInPrevModule - 1);
      setCurrentSelectionIndexes(selectedModuleIndex - 1 + "_" + (numLessonsInPrevModule - 1));
    }
  };

  const handleNextLesson = () => {
    const module = trainingData?.modules[selectedModuleIndex];
    const topic_id = module.topics[selectedTopicIndex]?.id; // Get topic_id
    markLessonAsCompleted(selectedModuleIndex, selectedTopicIndex, topic_id);
    if (module && selectedTopicIndex < module.topics.length - 1) {
      // Update to access lesson using the new data format
      setSelectedTopicIndex(selectedTopicIndex + 1);
      setCurrentSelectionIndexes(selectedModuleIndex + "_" + (selectedTopicIndex + 1));
    } else if (selectedModuleIndex < trainingData?.modules.length - 1) {
      setSelectedModuleIndex(selectedModuleIndex + 1);
      setSelectedTopicIndex(0);
      setCurrentSelectionIndexes(selectedModuleIndex + 1 + "_" + (selectedTopicIndex));
    }
  };

  const markLessonAsCompleted = (moduleIndex, topicIndex,topic_id) => {
    console.log("Topic ID:", topic_id);
    // Mark the lesson as completed
    const updatedCompletedLessons = completedLessons;
    if (!updatedCompletedLessons[moduleIndex]) {
      updatedCompletedLessons[moduleIndex] = [];
    }
    updatedCompletedLessons[moduleIndex][topicIndex] = true;
    setCompletedLessons(updatedCompletedLessons);
    console.log("completedlessons", completedLessons);    
    console.log("Topic ID:", topic_id);
     axiosBaseURL.post(`/trainings/${state.id}/curriculum/${topic_id}/complete/`)
    .then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const calculateCompletionPercentage = () => {
    if (!trainingData) return 0;
    const totalLessons = trainingData.modules.reduce((total, module) => total + module.topics.length, 0);
    const completedLessonsCount = (array) => array.filter((value) => value === true).length;
      let totalCompleted = 0;
      Object.keys(completedLessons).forEach((key) => {
        // Add the count of true values in the current array to the total count
        const trueValuesCount = completedLessons[key].filter((value) => value === true).length;
        totalCompleted += trueValuesCount; 
    }      
    );
    console.log("total topics:", totalLessons);
    return (totalCompleted / totalLessons) * 100;
  };

  const fetchTopic = () => {
    let module = trainingData?.modules[selectedModuleIndex];
    if (!trainingData){
      return;
    }
    
    let topic = trainingData.modules[selectedModuleIndex];
    if (topic){
      topic = topic.topics[selectedTopicIndex];
    }
    if(typeof topic == "undefined"){
      setSelectedModuleIndex(0);
      setSelectedTopicIndex(0);
      module = trainingData?.modules[0];
      topic = trainingData.modules[0].topics[0];
    }
    console.log(module, selectedModuleIndex, selectedTopicIndex, topic);
    axiosBaseURL.get(`/trainings/${trainingData.training_id}/${module.id}/${topic.file_name}`)
    .then((response) => {
      const htmlpart = response.data
      setLessonContent(htmlpart);
    })
    .catch((error) => {
      console.error('Error fetching lesson content:', error);
    });
  }

  let topic_name = null; // Define topic_name here to display on top of lesson content
  if (trainingData && trainingData.modules[selectedModuleIndex] && trainingData.modules[selectedModuleIndex].topics[selectedTopicIndex]) {
    topic_name = trainingData.modules[selectedModuleIndex].topics[selectedTopicIndex].name;
  }

  return (
  <div className="page-container">
    <div className={`sidebar ${!sidebarVisible ? 'hidden' : ''}`}>
        <div className="sidebar-header" id='sidebar-header-visible'>
          <div className="sidebar-toggle-icon" onClick={toggleSidebar}>
            <div className={`dot ${sidebarVisible ? 'active' : ''}`}></div>
            <div className={`dot ${sidebarVisible ? 'active' : ''}`}></div>
            <div className={`dot ${sidebarVisible ? 'active' : ''}`}></div>
          </div>
          <div className='topic-name-in-header-1'>{topic_name}</div>
        </div>
        <div className={`sidebar-content ${sidebarVisible ? 'visible' : ''}`}>
        <div className='course-header' id='course-header-sidebar-visible'>
              <div className='back-icon'>
              <img src={back_icon} alt="Home" onClick={() => navigate('/home')}/>
              </div>
              <div className="training-name">{trainingData?.training_name}</div>
              <div className='progress-bar-component'><CircularProgress percentage={calculateCompletionPercentage()} /></div>
        </div>
          <div className="sidebar-container">
            <div className="module-list">
            {trainingData?.modules.map((module, moduleIndex) => (
              <div key={module.id} className={`module ${moduleIndex === selectedModuleIndex ? 'active' : ''}`}>
                <div className="module-name">{module.name}</div>
                <div className="lesson-list">
                    {module.topics.map((topic, topicIndex) => (
                      <div
                          key={topic.id}
                          className={`lesson ${
                            moduleIndex === selectedModuleIndex && topicIndex === selectedTopicIndex ? 'active' : ''
                          }${completedLessons[moduleIndex]?.[topicIndex] ? 'completed' : ''} `}
                          ref={(ref) => {
                          // Store the ref to the topic element and scroll if it's the selected topic
                          if (moduleIndex === selectedModuleIndex && topicIndex === selectedTopicIndex && ref) {
                              ref.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                              }
                          }}
                          onClick={() => handleLessonClick(moduleIndex, topicIndex, topic)}
                        >
                          <div className="lesson-item">
                            {completedLessons[moduleIndex]?.[topicIndex] ? (
                            <div className="tick-mark"><img src={tick_icon } alt="Tick" className="tick-icon" /></div>
                            ) : (
                            //<span className="cross-mark">×</span>
                            <div className="ring-mark"><img src={ring_icon} alt="cross" className="ring-icon" /></div>
                            )}
                            {topic.name}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            ))}
                {/* added br tags for extra padding below lessons since it was hiding behind the certificate section */}
              {/*  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>*/}
            </div>
          </div>
          <div className="certificate-module">
            <div className="internship-certificate" id="certificate-id"
              onClick={() => handleLessonClick(0, 0, "certificate")} >
                <img src={certificate_icon} alt="Certificate" className="certificate-icon" />
                <div className='certificate-text'>Certificate</div>
            </div>
            <div className="internship-certificate"
              onClick={() => handleLessonClick(0, 0, "internship")}>
                <img src={intern_with_stipend_icon} alt="Internship" className="certificate-icon" />
                <div className='certificate-text'>Internship</div>
            </div>
          </div>
        </div>
    </div>

    <div className={`main-content ${sidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}>
      <div className="main-header" id="main-header-for-toggle">
        <div className="sidebar-toggle-icon"  onClick={toggleSidebar}>
            <div className={`dot ${sidebarVisible ? 'active' : ''}`}></div>
            <div className={`dot ${sidebarVisible ? 'active' : ''}`}></div>
            <div className={`dot ${sidebarVisible ? 'active' : ''}`}></div>
        </div>
        <div className='topic-name-in-header-1'>{topic_name}</div>
        <div className='course-header' id='course-header-for-toggle'>
              <div className='back-icon'>
              <img src={back_icon} alt="Home" onClick={() => navigate('/home')}/>
              </div>
              <div className="training-name">{trainingData?.training_name}</div>
              <div className='progress-bar-component'><CircularProgress percentage={calculateCompletionPercentage()} /></div>
        </div>
      </div>
      <div className={`lesson-content ${viewportWidth <= 768 ? 'phone-view' : ''}`}>
        <div className='topic-info'>
          <div className='topic_name'>{topic_name}</div>
        </div>
        {selectedTopicIndex !== null && (
          <div className="lesson-screen" dangerouslySetInnerHTML={{ __html: lessonContent }} />
          )}
          {/* <div className="prev-next-buttons-container"> */}
          {/* Arrow buttons for phone/tab view */}
          {/* <button className="prev-next-button arrow-button" onClick={handlePrevLesson}> */}
            {/* <span className="arrow left"></span> Arrow icon */}
          {/* </button> */}
          {/* <button className="prev-next-button arrow-button" onClick={handleNextLesson}> */}
            {/* <span className="arrow right"></span> Arrow icon */}
          {/* </button> */}
          {/* Original Next and Previous buttons for web view */}
          
          <div className='web-prev-next-button-container'>
          <button className="prev-next-button-web" onClick={handlePrevLesson}>
            <img src={prev_icon} alt='previous' className='previous-icon'/>
            Previous</button>
          <button className="prev-next-button-web" onClick={handleNextLesson}>
          Complete & Next
          <img src={next_icon} alt='next' className='next-icon'/>
          </button>
          </div>
          {/* added br tags for extra padding below lesson content since it was hiding behind the buttons */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
      </div>
    </div>
  </div>
  );
};

export default TrainingsPage;

import React from 'react';
import './CircularProgress.css';

const CircularProgress = ({ percentage }) => {
    const radius = window.innerWidth <= 600 ? 30 : 37;
    const stroke = window.innerWidth <= 600 ? 6 :7;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;
  
    return (
      <div className='circular-progress-container'>
        <svg
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            stroke="#D9D9D9"
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="#9BDF46"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{ strokeDashoffset }}
            strokeLinecap="round"
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <div className="progress-number">
        {`${Math.floor(percentage)}%`}
        </div>
      </div>
    );
  };
  
  export default CircularProgress;
// ToggleButton.js

import React from 'react';

import './ToggleSidebar.css';

const ToggleSidebar = ({ onClick, isFixed }) => (
  <div className={`toggle-btn ${isFixed ? 'fixed' : ''}`} onClick={onClick}>
    <div className="line"></div>
    <div className="line"></div>
    <div className="line"></div>
  </div>
);

export default ToggleSidebar;

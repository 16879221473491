import React from 'react';
import Header from './header';
import ProductsPage from './ProductsPage';
import TrainingsPage from './TrainingsPage';
import IntenshipsStipendPage from './InternshipsStipendPage'
import AssuredInternshipsPage from './AssuredInternshipsPage'
import AiDebuggerPage from './AiDebuggerPage';
import InternshipFaqPage from './InternshipFaqPage';
import FacultyDashboard from './FacultyDashboard';
import ApiUsage from './ApiUsage';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/home" element={<ProductsPage />} />
        <Route path="/trainings/*" element={<TrainingsPage />} />
        <Route path="/internshipwithstipend" element={<IntenshipsStipendPage />} />
        <Route path="/assuredinternships" element={<AssuredInternshipsPage />} />
        <Route path="/AICodeDebugger" element={<AiDebuggerPage />} />
        <Route path="/internshipFAQ" element={<InternshipFaqPage/>} />
        <Route path="/facultydashboard" element={<FacultyDashboard />} />
        <Route path="/apiusage" element={<ApiUsage />} />
        <Route path="*" element={<Navigate to="/home" />} />

        {/* Add more routes as needed */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// AssuredInternshipsPage.js

import React, { useState , useEffect} from 'react';
import HubspotForm from 'react-hubspot-form';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './AssuredInternshipsPage.css';
import axiosBaseURL from './httpCommon';

import profile_img from './images/profile_image.png';
import home_icon from './images/home-icon.png';
import whatsapp_icon from './images/whatsapp_icon.png'
import Boltfooter from './Footer';
import BoltHeader from './BoltHeader';


const Accordion = ({ title, children, expanded, onAccordionChange }) => {
  return (
    <div className={`accordion ${expanded ? 'expanded' : ''}`} onClick={onAccordionChange}>
      <div className="accordion-header">
        <h4>{title}</h4>
        <span className={`arrow-icon ${expanded ? 'up' : 'down'}`}>▼</span>
      </div>
      {expanded && (
        <div className="accordion-content">
          {children}
        </div>
      )}
    </div>
  );
};

const AssuredInternshipsPage = () => {
  const { state } = useLocation();
  const [accordionExpanded, setAccordionExpanded] = useState({});
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [trainings_enrolled, setTrainingsEnrolled] =useState([]);
  const [internshipsUnlocked, setInternshipsUnlocked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTrainings();
    }, [])
  
  useEffect(() => {
    console.log("Use effect",trainings_enrolled)
    console.log("Use Effect", internshipsUnlocked)
    }, [trainings_enrolled, internshipsUnlocked])

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);
  

  const fetchTrainings = () => {
    axiosBaseURL.get(`/trainings/`)
    .then((response) => {
      const data = response.data
      console.log(data.data)
      setTrainingsEnrolled(data.data.trainings_enrolled);    
      console.log(trainings_enrolled)
      if(data.data.internship_unlocked == true){
        setInternshipsUnlocked(true)
       }
      console.log(internshipsUnlocked)
    })
    .catch((error) => {
      navigate('/');
    });
    }

  const handleProfileClick = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleLogout = () => {
    axiosBaseURL.get('/logout')
      .then(response => {
        navigate('/');
      })
      .catch(error => {
        console.error('Error during logout:', error);
        navigate('/');
      });
  };

  const handleAccordionChange = (index) => {
    setAccordionExpanded(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const AccordionHeadings = {
    "Online Training on Internet of Things and Machine Learning": "Tasks for IoT and ML Assured Internship",
    "Artificial Intelligence Training":"Tasks for the Artificial Intelligence Assured Internship",
    "Web Development Training on HTML, CSS and JavaScript":"Tasks for the Web Development Assured Internship",
    "Online Training on Python Programming":"Tasks for the Python Programming Assured Internship",
    "Online Training on Robotics and Arduino":"Tasks for the Robotics Assured Internship",
    "Voice Apps Online Training":"Tasks for the Voice Apps Assured Internship",
    "Augmented Reality Training":"Tasks for the Augmented Reality Assured Internship",
  };

  const CombinationHeadings = {
    "Combination 1": "Tasks for 6 Month Internship - AI, IoT&ML and Web Dev",
    "Combination 2": "Tasks for 6 Month Internship - AI, Python and Web Dev",
    "Combination 3": "Tasks for 4 Month Internship - AI and Web Dev",
  };

  const generateInternshipContent = (tasks) => (
    <div>
      <h3>Task 1</h3>
      <ol>
        {tasks.map((task, index) => (
          <li key={index}>
            <strong>{task.title}:</strong>{" "}
            <span dangerouslySetInnerHTML={{ __html: task.description }} />
            <br />
          </li>
        ))}
      </ol>
  
      <h3>Task 2</h3>
      <ol>
        <li>
          <strong>Forum Queries :</strong> Answer 4 forum queries on <a href="https://forum.boltiot.com/" target="_blank">https://forum.boltiot.com/</a>. The answers need to be precise and should provide an accurate fix to the question posted.
        </li>
      </ol>
    </div>
  );

  const AccordionContent = {
    "Online Training on Internet of Things and Machine Learning": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/iotandmlproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/iotandmlproblemstatements</a>"
      }]),
    "Artificial Intelligence Training": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/artificialintelligenceproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/artificialintelligenceproblemstatements</a>"
      }]),
    "Web Development Training on HTML, CSS and JavaScript": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/webdevelopmentproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/webdevelopmentproblemstatements</a>"
      }]),
    "Online Training on Python Programming": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/pythonprogrammingproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/pythonprogrammingproblemstatements</a>"
      }]),
    "Online Training on Robotics and Arduino": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/roboticsproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/roboticsproblemstatements</a>"
      }]),
    "Voice Apps Online Training":  generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/voiceappsproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/voiceappsproblemstatements</a>"
      }]),
    "Augmented Reality Training": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/augmentedrealityproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/augmentedrealityproblemstatements</a>"
      }]),
    "Combination 1": generateInternshipContent([
      {
      title: "Click on the link to view the problem statements",
      description: "<a href='https://beacon.by/bolt-iot/iotml-ai-webdev-6monthinternshipproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/iotml-ai-webdev-6monthinternshipproblemstatements</a>"
      }]),
    "Combination 2": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/python-ai-webdev-6monthinternshipproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/python-ai-webdev-6monthinternshipproblemstatements</a>"
      }]),
    "Combination 3": generateInternshipContent([
      {
        title: "Click on the link to view the problem statements",
        description: "<a href='https://beacon.by/bolt-iot/ai-webdev-4monthinternshipproblemstatements' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/ai-webdev-4monthinternshipproblemstatements</a>"
      }]),
  };

  //Check if "Advanced IoT and ML Training" or "Placement Preparation Training" is present in the response
  const hideAccordion = (training) => {
      return !["Advanced IoT and ML Training", "Placement Preparation Training"].includes(training);
  };

  const combinations = [
    ['Artificial Intelligence Training', 'Online Training on Internet of Things and Machine Learning', 'Web Development Training on HTML, CSS and JavaScript'],
    ['Artificial Intelligence Training', 'Online Training on Python Programming', 'Web Development Training on HTML, CSS and JavaScript'],
    ['Artificial Intelligence Training','Web Development Training on HTML, CSS and JavaScript'],
  ];

  const renderCombinationAccordions = () => {
    return combinations.map((combination, index) => {
      const shouldDisplayAccordion = combination.every(training => trainings_enrolled.includes(training));
      if (shouldDisplayAccordion) {
        return (
          <Accordion
            key={`combination-${index}`}
            title={CombinationHeadings[`Combination ${index + 1}`]}
            expanded={accordionExpanded[`combination-${index}`]}
            onAccordionChange={() => handleAccordionChange(`combination-${index}`)}
          >
          {/* Display content for the combination defined in AccordionContent */}
          {AccordionContent[`Combination ${index + 1}`]}
          </Accordion>
        );
      }
      return null;
    });
  };
  
  const renderIndividualTrainingAccordions = () => {
    return trainings_enrolled.map((training, index) => (
      // Add a condition to check whether to display the Accordion
      hideAccordion(training) && (
        <Accordion
          key={index}
          title={AccordionHeadings[training]}
          expanded={accordionExpanded[index]}
          onAccordionChange={() => handleAccordionChange(index)}
        >
          {/* Display specific content for each training */}
          {AccordionContent[training]}
        </Accordion>
      )
    ));
  };

  return (
    <div className="assured-internships-page">
      <BoltHeader/>
      {/* Assured Internships information */}
      <div className="assured-internships-info">
        <h1 className="internship-heading">Assured Internships by Bolt IoT</h1>
        <ol className="internship-info">
          <li>Click on the link to view the rules of the Internship: <a href='https://beacon.by/bolt-iot/assuredinternshiprules' target='_blank' rel='noopener noreferrer'>https://beacon.by/bolt-iot/assuredinternshiprules</a></li>
          <li>Submit your internship tasks by filling the form provided below.</li>
          <li>If you encounter any issues, or your certificate is not issued on time please send an email to <a href="mailto:support@boltiot.com">support@boltiot.com</a>.</li>
        </ol>
      </div>

      {/* Render combination accordions */}
      {renderCombinationAccordions()}

      {/* Render individual training accordions */}
      {renderIndividualTrainingAccordions()}

      <div className='form-text'>
        <h3>Fill the form given below to submit your internship tasks</h3>
        <ol className="internship-info">
          <li>Put all your Screenshots, code files, demo videos(approx 5 minutes), code explanation video(approx 5 minutes), etc. in a folder. Upload this folder on Google Drive, make the folder public and add the link in the form.</li>
          <li>Your submission will be reviewed within 2 working days [Monday to Friday]. If approved, certificate will be shared else feedback will be provided for required improvements. All updates will be shared on Email.</li>
          <li>Please retain your submitted projects until the internship certificate is approved. This will allow you to incorporate for any potential feedback or updates. If feedback is provided, you will have the original project on hand for quick revisions.Keep your project files saved until you receive confirmation of internship certificate approval.</li>
          <li><strong>Note: </strong>This is a live internship. The projects you submit will be owned by Inventrom Pvt. Ltd. and may be used for teaching other students as well as any other business activities carried out by the company. Hence it is important that you do not plagiarize/copy any resources or code from somewhere else. If in case you plagiarize/copy, make sure that the source of the content allows for it and attribute them in your work. If we find that you have plagiarized/copied the content without attributing the source of it, then we will not consider your project submission and not send the Internship Certificate.</li>
        </ol>

      </div>
      
      <div className="form-container">
        {/* HubSpot Form */}
        <HubspotForm
          portalId="4801849"
          formId="ab433e83-0115-4095-a106-a5b563c81155"
          loading={<div>The Form is Loading...</div>}
        />

        {/* Overlay and text */}
        {!internshipsUnlocked && (
          <div className="glassy-overlay">
            <div className="overlay-text" style={{ fontSize: '1.9rem', textAlign: 'center' }}>
              You will be able to submit your Internship Tasks after you complete the training as part of the Internship Learning Goals...
              <br />
              Click here to complete the training:
              <br />
              <button style={{ 
                backgroundColor: '#007bff', 
                color: '#fff', 
                border: 'none', 
                padding: '0.6rem 1.2rem', 
                borderRadius: '5px', 
                cursor: 'pointer' 
              }}>
                <a href="https://trainings.boltiot.com/home" style={{ color: '#fff', textDecoration: 'none' }}>Complete Training</a>
              </button>
            </div>
          </div>

        )}
      </div>

              <Boltfooter/>
    </div>
  );
};

export default AssuredInternshipsPage;

// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-item footer-left">© Bolt IoT 2023</div>
      <div className="footer-item footer-center">
        <a href="https://www.boltiot.com/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Use</a>
      </div>
      <div className='footer-item footer-right'>
        <a href="https://www.boltiot.com/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      </div>
    </footer>
  );
}


export default Footer;

// LessonPlanGenerator.js

import React from 'react';
import Iframe from './iframe';
import HubspotForm from 'react-hubspot-form';

import './LessonPlan.css';

const LessonPlanGenerator = () => {
  // const iframeUrl = 'https://info.boltiot.com/lessonplan';
  const iframeUrl = 'https://lessonplan.replit.app';
  // Your AI Lesson Plan Generator content goes here
  return (
    <div className="lesson-plan-generator-container">
      {/* Add your content here */}
      <h1 className="internship-heading">AI Lecture Plan Generator</h1>
      <p>Get a first draft outline of a lecture/course plan generated by artificial intelligence.</p>
      <p><b>Need help? Call us on (+91) 7020581998</b></p>
      <Iframe iframeUrl={iframeUrl} />
      <h4>Please share your feedback on using the AI lecture plan generator</h4>
      <HubspotForm
        portalId="4801849"
        formId="62e22e0f-3247-4ea0-9f22-4013d355504f"
        loading={<div>The Form is Loading...</div>}
      />
      <br />
      <br />
      <br />
    </div>
  );
};

export default LessonPlanGenerator;

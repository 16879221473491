// Support.js

import React from 'react';
import Iframe from './iframe';

import './LessonPlan.css';

const Support = () => {
  const iframeUrl = 'https://info.boltiot.com/facultysupport'; 
  // Your AI Lesson Plan Generator content goes here
  return (
    <div className="lesson-plan-generator-container">
      {/* Add your content here */}
      <Iframe iframeUrl={iframeUrl} />
    </div>
  );
};

export default Support;
